<template>
    <textarea
        v-model="inputValue"
        class="form-control max-w-full w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid rounded-xl transition ease-in-out m-0 resize-y focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
        rows="3"
        :class="{
            'border-box-stroke': !error,
            'border-red-600': error,
        }"
        :placeholder="getPlaceHolder"
        @input="onInput"
        :disabled="disabled || formAlreadySubmitted"
    ></textarea>
</template>

<script>
import debounce from 'lodash/debounce';
export default {
    props: {
        fieldData: {
            type: Object,
            require: true,
        },
        error: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        formAlreadySubmitted: {
            type: Boolean,
        }
    },
    data() {
        return {
          inputValue: this.fieldData.value || '',
        };
    },
    created() {
    this.debouncedUpdateValue = debounce(this.updateValue, 300); // Debounce for 500 milliseconds to resolve the input value log
    },
    computed: {
        getPlaceHolder() {
            return this.fieldData.placeholder || ''
        },
        getValue: {
            get() {
                return this.fieldData.value || ''
            },
            set(newValue) {
                // let data = { value: newValue, field_id: this.fieldData.field_id };
                // if (this.fieldData.multi_id) {
                //     data.multi_id = this.fieldData.multi_id;
                //     delete data.field_id
                // }
                // EventBus.$emit("changeFieldValue", data);
                this.$emit('input', newValue);
            }
        }
    },
    methods: {
        handleChange({target}) {
            this.getValue = target.value
            this.$emit("handleFieldValueChnage", target.value);
        },
        onInput(newValue) {
            this.inputValue = newValue?.target?.value;
            this.debouncedUpdateValue(newValue);
        },
        updateValue(newValue) {
          this.$emit('input', newValue?.target?.value); 
          this.$emit('handleFieldValueChange', newValue?.target?.value); 
        },
    }
};
</script>

<style>
textarea:focus {
    box-shadow: 0 0 0 1px rgba(37, 99, 235, var(--tw-border-opacity)) !important;
}
@media screen and (max-width:768px) {
    textarea{
        border-radius:0px;
    }
    
}
</style>